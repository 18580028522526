import { PicoClient, PicoEvent } from '@bendingspoons/pico-core';
import { PicoEventMetadataObject } from '@bendingspoons/pico-core';
import { createContext, useContext } from 'react';

import { TrackingConfiguration } from '@/configuration/types';

const PicoContext = createContext<PicoClient | undefined>(undefined);

/** Provides a general instance of PicoClient to the application. */
export const GeneralPicoProvider = PicoContext.Provider;

/** Hook to access the PicoClient instance. */
export const usePico = () => useContext(PicoContext);

/** Returns the user info read from trackingConfiguration. */
export const getPicoUserInfo = (
  trackingConfiguration: TrackingConfiguration
): PicoEventMetadataObject => ({
  device: {
    platform: trackingConfiguration.platform,
    browser: trackingConfiguration.browser,
    os_version: trackingConfiguration.osVersion,
    language: trackingConfiguration.browserLanguage,
  },
  language: trackingConfiguration.uiLanguage,
  gclid: trackingConfiguration.gclid ?? 'null',
  fbclid: trackingConfiguration.fbclid ?? 'null',
  ttclid: trackingConfiguration.ttclid ?? 'null',
  landing_page_info: {
    t_s: trackingConfiguration.ts ?? 'null',
    t_cid: trackingConfiguration.tcid ?? 'null',
    t_cname: trackingConfiguration.tcname ?? 'null',
    t_agid: trackingConfiguration.tagid ?? 'null',
    t_agname: trackingConfiguration.tagname ?? 'null',
    t_crid: trackingConfiguration.tcrid ?? 'null',
    t_crname: trackingConfiguration.tcrname ?? 'null',
    t_match_type: trackingConfiguration.tmatchType ?? 'null',
    t_network: trackingConfiguration.tnetwork ?? 'null',
    t_device: trackingConfiguration.tdevice ?? 'null',
    t_gcid: trackingConfiguration.tgcid ?? 'null',
    t_validation: trackingConfiguration.tvalidation ?? 'null',
    utm_campaign: trackingConfiguration.utmCampaign ?? 'null',
    utm_content: trackingConfiguration.utmContent ?? 'null',
    liteclientId: trackingConfiguration.liteclientId ?? 'null',
    websiteId: trackingConfiguration.websiteId ?? 'null',
  },
});

export const deleteNullValuesFromUserInfo = (event: PicoEvent) => {
  for (const member in event.userInfo) {
    if (event.userInfo[member] === 'null') {
      delete event.userInfo[member];
    }
  }
  const landing_page_info: PicoEventMetadataObject = event.userInfo
    .landing_page_info as PicoEventMetadataObject;
  if (!landing_page_info) {
    return;
  }

  for (const member in landing_page_info) {
    if (landing_page_info[member] === 'null') {
      delete landing_page_info[member];
    }
  }
  if (Object.keys(landing_page_info).length === 0) {
    delete event.userInfo.landing_page_info;
  }
  return event;
};
