import { PicoClient } from '@bendingspoons/pico-core';

export const mockPico: PicoClient = {
  trackUserAction: () => {},
  trackExperimentSegmentReceived: () => {},
  getUserInfo: () => {
    return Promise.resolve({});
  },
  updateUserInfo: () => {
    return Promise.resolve();
  },
  trackSignInAction: () => {},
};
