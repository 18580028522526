'use client';

import { Language } from '@/shared/language';

// Can be dynamically generated pre-build
const availableLocales: Language[] = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
];

/**
 * Returns the best locale for the user.
 *
 * WARNING: this function is used during rendering of the TranslationProvider component.
 * For this reason, it should rely only on lightweight client-side logic.
 */
export function getBestLocale(): Language {
  const browserLanguage = navigator.language.split('-')[0] as Language;
  return availableLocales.includes(browserLanguage) ? browserLanguage : 'en';
}
