import { LOCAL_STORAGE_ID_COOKIE_NAME } from '@/shared/constants';
import { EventProcessor } from '@bendingspoons/pico-core';
import Cookie from 'js-cookie';

import { deleteNullValuesFromUserInfo } from './picoUtils';

function removeNullInfosProcessor(): EventProcessor {
  return {
    processEvent: (event) => {
      if (event?.userInfo) {
        deleteNullValuesFromUserInfo(event);
      }
      return event;
    },
  };
}

/** This one is needed for the Pico event processor to work on auth pages, since with the TCA setup PicoProvider doesn't work onMount events.
 *  Needs to be refactored once getPico is implemented. */
function addLocalStorageIdCookie(): EventProcessor {
  return {
    processEvent: (event) => {
      const localStorageId = localStorage.getItem(
        'LOCAL_STORAGE_ID_evernote_user_service'
      );
      if (localStorageId) {
        Cookie.set(LOCAL_STORAGE_ID_COOKIE_NAME, localStorageId, {
          expires: 365, // 1 year
          sameSite: 'None',
          secure: true,
        });
      }

      return event;
    },
  };
}

/** Collection of Pico event processors. */
export const picoEventProcessors = {
  removeNullInfosProcessor,
  addLocalStorageIdCookie,
};
