import { REDIRECT_TO_COOKIE_NAME } from '@/shared/constants';
import Cookie from 'js-cookie';

export function storeRedirectTo(url: string) {
  Cookie.set(REDIRECT_TO_COOKIE_NAME, url, {
    sameSite: 'None',
    secure: true,
    expires: 10 / (24 * 60), // 10 minutes
  });
}
