'use client';

import { PicoClient, createPico } from '@bendingspoons/pico-core';
import {
  indexedDBPersistence,
  localStorageID,
  webLockApiRunner,
} from '@bendingspoons/pico-web';
import { v4 as uuidv4 } from 'uuid';

import { getEnvironment } from '@/configuration/AppContext';
import { mockPico } from '@/pico/mockPico';
import { picoEventProcessors } from '@/pico/picoEventProcessors';
import { BSP_ID } from '@/pico/PicoProvider';

export class Pico {
  /**
   * Returns the shared Pico instance.
   *
   * Multiple calls will return the same instance.
   *
   * Calls are transparently queued until Pico is ready,
   * ensuring that callers do not risk losing events or need to handle the complexity of waiting for Pico to initialize.
   *
   * In case of setup failure, an error is printed and all calls will be a no-op.
   */
  static get shared(): PicoClient {
    if (Pico.#shared) {
      return Pico.#shared;
    }
    const picoPromise = createPicoOrFallbackToMock();
    Pico.#shared = {
      trackExperimentSegmentReceived(segments) {
        picoPromise.then((pico) =>
          pico.trackExperimentSegmentReceived(segments)
        );
      },
      trackUserAction(event) {
        picoPromise.then((pico) => pico.trackUserAction(event));
      },
      trackSignInAction(event) {
        picoPromise.then((pico) => pico.trackSignInAction(event));
      },
      getUserInfo() {
        return picoPromise.then((pico) => pico.getUserInfo());
      },
      updateUserInfo(userInfo) {
        return picoPromise.then((pico) => pico.updateUserInfo(userInfo));
      },
    };
    return Pico.#shared;
  }
  static #shared: PicoClient | null = null;
}

async function createPicoOrFallbackToMock(): Promise<PicoClient> {
  const persistenceManager = await indexedDBPersistence();
  const result = await createPico({
    persistenceManager,
    userIdGenerators: {
      localStorageId: localStorageID(BSP_ID, uuidv4),
    },
    environment:
      getEnvironment() === 'production'
        ? { type: 'production' }
        : { type: 'staging' },
    bspId: BSP_ID,
    uuidFactory: uuidv4,
    logger: {
      info: console.log,
      trace: console.log,
      warn: console.warn,
      error: console.error,
      debug: console.debug,
      fatal: console.error,
    },
    lockRunner: navigator.locks
      ? webLockApiRunner()
      : (_lockId, callback) => {
          callback();
        }, // In mobile browsers, navigator.locks is not available. We don't use any lock in this case.
    eventProcessors: [
      picoEventProcessors.removeNullInfosProcessor(),
      picoEventProcessors.addLocalStorageIdCookie(),
    ],
  });

  if (result.result === 'success') {
    return result.pico;
  }

  console.error('Pico init error', result.error);
  return mockPico;
}
