'use client';

import React from 'react';

import { Pico } from '@/pico/Pico';

import { GeneralPicoProvider } from './picoUtils';

export const BSP_ID = 'evernote_user_service';

export const PicoProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <GeneralPicoProvider value={Pico.shared}>{children}</GeneralPicoProvider>
  );
};
