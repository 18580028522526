'use client';

import { SESSION_STORAGE_KEYS } from '@/shared/constants';
import { useSearchParams } from 'next/navigation';
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { AppConfiguration, AppContextType } from '@/configuration/types';
import { getBestLocale } from '@/i18n';
import { Pico } from '@/pico/Pico';
import { getPicoUserInfo } from '@/pico/picoUtils';
import { detectPlatform } from '@/utils/detectPlatform';
import { storeRedirectTo } from '@/utils/tracking';

type Environment = 'development' | 'testing' | 'staging' | 'production';

export function getEnvironment(): Environment {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  if (
    !environment ||
    (environment !== 'development' &&
      environment !== 'testing' &&
      environment !== 'staging' &&
      environment !== 'production')
  ) {
    return 'production';
  }
  return environment;
}

export function getMonolithUrl(): string {
  const environment = getEnvironment();
  return {
    development: 'http://127.0.0.1:8080',
    testing: 'https://app.preprod3.evernote.com',
    staging: 'https://stage.evernote.com',
    production: 'https://www.evernote.com',
  }[environment];
}

export function getUserServiceUrl(): string {
  const environment = getEnvironment();
  return {
    development: 'https://accounts.development.evernote.com',
    testing: 'https://accounts.preprod3.evernote.com',
    staging: 'https://accounts.stage.evernote.com',
    production: 'https://accounts.evernote.com',
  }[environment];
}

export function getApiGatewayUrl(): string {
  const environment = getEnvironment();
  return {
    development: 'https://api.testing.evernote.com',
    testing: 'https://api.testing.evernote.com',
    staging: 'https://api.staging.evernote.com',
    production: 'https://api.evernote.com',
  }[environment];
}

export function getGoogleDrivePickerClientId(): string {
  const clientId = process.env.NEXT_PUBLIC_GOOGLE_DRIVE_PICKER_CLIENT_ID;
  if (!clientId) {
    // Default value: the ClientId used to open the Drive Picker in production
    return '447407681759-0oe3cc7cvr7plt4g6vkg87ml9j8pfi6d.apps.googleusercontent.com';
  } else {
    return clientId;
  }
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const environment = getEnvironment();
  const appConfiguration: AppConfiguration = {
    environment,
    monolithBaseURL: getMonolithUrl(),
    userServiceBaseURL: getUserServiceUrl(),
  };

  const searchParams = useSearchParams();

  useLayoutEffect(() => {
    // one time setup to store redirect and set pico info
    // useLayoutEffect allows to execute before other effects, setting pico user info asap
    const platform = detectPlatform().os || 'unknown';
    const browser = detectPlatform().browser || 'unknown';
    const osVersion = detectPlatform().osVersion || 'unknown';

    const browserLanguage = navigator.language.split('-')[0];
    const uiLanguage = getBestLocale();

    const gclid = searchParams.get('gclid') ?? undefined;
    const fbclid = searchParams.get('fbclid') ?? undefined;
    const ttclid = searchParams.get('ttclid') ?? undefined;
    const ts = searchParams.get('t_s') ?? undefined;
    const tcid = searchParams.get('t_cid') ?? undefined;
    const tcname = searchParams.get('t_cname') ?? undefined;
    const tagid = searchParams.get('t_agid') ?? undefined;
    const tagname = searchParams.get('t_agname') ?? undefined;
    const tcrid = searchParams.get('t_crid') ?? undefined;
    const tcrname = searchParams.get('t_crname') ?? undefined;
    const tmatchType = searchParams.get('t_match_type') ?? undefined;
    const tnetwork = searchParams.get('t_network') ?? undefined;
    const tdevice = searchParams.get('t_device') ?? undefined;
    const tgcid = searchParams.get('t_gcid') ?? undefined;
    const tvalidation = searchParams.get('t_validation') ?? undefined;
    const utmCampaign = searchParams.get('utm_campaign') ?? undefined;
    const utmContent = searchParams.get('utm_content') ?? undefined;
    const liteclientId = searchParams.get('liteclient_id') ?? undefined;
    const redirectTo = searchParams.get('redirect_to') ?? undefined;
    const websiteId = searchParams.get('website_id') ?? undefined;

    if (redirectTo) {
      storeRedirectTo(redirectTo);
    }

    void Pico.shared.updateUserInfo(
      getPicoUserInfo({
        platform,
        browser,
        osVersion,
        browserLanguage,
        uiLanguage,
        gclid,
        fbclid,
        ttclid,
        ts,
        tcid,
        tcname,
        tagid,
        tagname,
        tcrid,
        tcrname,
        tmatchType,
        tnetwork,
        tdevice,
        tgcid,
        tvalidation,
        utmCampaign,
        utmContent,
        liteclientId,
        websiteId,
      })
    );
  }, []); // execute only once

  const [email, setEmail] = useState('');

  // Get the email from sessionStorage when the component mounts
  useEffect(() => {
    const storedEmail = sessionStorage.getItem(SESSION_STORAGE_KEYS.EMAIL);
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  // Update the email in sessionStorage whenever it changes
  useEffect(() => {
    if (email) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.EMAIL, email);
    }
  }, [email]);

  return (
    <AppContext.Provider
      value={{
        email,
        setEmail,
        appConfiguration,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
