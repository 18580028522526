export enum OSType {
  Windows = 'windows',
  Mac = 'mac',
  Linux = 'linux',
  IPhone = 'iphone',
  IPad = 'ipad',
  Android = 'android',
}

type PlatformInfo = {
  os: OSType | undefined;
  osVersion: string | undefined;
  browser: string | undefined;
  browserVersion: string | undefined;
};

export const detectPlatform = (): PlatformInfo => {
  // Define user agent removing the mozilla version from string
  const userAgent = navigator.userAgent
    .toLowerCase()
    .replace(/^mozilla\/\d\.\d\W/, '');

  const mobiles: { [key in OSType]?: RegExp } = {
    [OSType.IPhone]: /iphone os ([\d_]+)/,
    [OSType.IPad]: /ipad; cpu os ([\d_]+)/,
    [OSType.Android]: /android ([\d.]+)/,
  };

  const desktops: { [key in OSType]?: RegExp } = {
    [OSType.Windows]: /windows nt ([\d.]+)/,
    [OSType.Mac]: /mac os x ([\d_]+)/,
    [OSType.Linux]: /linux/,
  };

  // This function determines the operating system (OS) and its version based on the user agent string.
  // It first checks if the user agent matches any known mobile OS patterns.
  // If a match is found, it sets the OS type and extracts the version number, replacing underscores with dots.
  // If no mobile OS match is found, it then checks for desktop OS patterns.
  // If a desktop OS match is found, it sets the OS type and extracts the version number.
  let os: OSType | undefined;
  let osVersion: string | undefined;

  const mobileMatch = Object.entries(mobiles).find(
    ([, regex]) => regex && regex.test(userAgent)
  );

  if (mobileMatch) {
    os = mobileMatch[0] as OSType;
    const matchResult = userAgent.match(mobileMatch[1]);
    if (matchResult && matchResult[1]) {
      osVersion = matchResult[1].replace(/_/g, '.');
    }
  } else {
    const desktopMatch = Object.entries(desktops).find(
      ([, regex]) => regex && regex.test(userAgent)
    );
    if (desktopMatch) {
      os = desktopMatch[0] as OSType;
      const matchResult = userAgent.match(desktopMatch[1]);
      if (matchResult && matchResult[1]) {
        osVersion = matchResult[1];
      }
    }
  }

  // Attempt to match the user agent string to browser/version format
  const browserTest = userAgent.match(/(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g);

  // Determine if an offset is needed to find the correct browser information
  // This is based on the presence of specific browser prefixes and the number of matches found
  // If more than two matches are found and the second match does not start with specific prefixes, an offset of 1 is used
  const browserOffset =
    browserTest &&
    (browserTest.length > 2 && !/^(ver|cri|gec)/.test(browserTest[1]) ? 1 : 0);

  // Extract the browser name and version using the calculated offset
  // If more than two matches are found and the second match does not start with specific prefixes, an offset of 1 is used
  const browserResult =
    browserTest &&
    browserTest[browserTest.length - 1 - (browserOffset || 0)].split('/');

  // Extract the browser name from the result, default to undefined if not available
  const browser = (browserResult && browserResult[0]) || undefined;

  // Extract the browser version from the result, default to undefined if not available
  const browserVersion = (browserResult && browserResult[1]) || undefined;

  return { os, osVersion, browser, browserVersion };
};
